// Website Variables and Colors
$base-font-family: "Open Sans", sans-serif;
$base-font-size: 16;
$base-line-height: 28;
$base-font-weight: 300;
$base-font-color: #494d4e;
$base-link-color: #c90;

// Headings
$base-heading-font-family: "Yeseva One", cursive;
$base-h1-font-size: 48;
$base-h1-line-height: 64;
$base-h1-font-weight: 400;
$base-h1-color: #fff;
$base-h2-font-size: 36;
$base-h2-line-height: 40;
$base-h2-font-weight: 400;
$base-h2-color: #c90;
$base-h3-font-size: 22;
$base-h3-line-height: 28;
$base-h3-font-weight: 400;
$base-h3-color: #c90;
$base-h4-font-size: 18;
$base-h4-line-height: 24;
$base-h4-font-weight: 400;
$base-h4-color: #c90;
$base-h5-font-size: 16;
$base-h5-line-height: 22;
$base-h5-font-weight: 400;
$base-h5-color: #c90;
$base-h6-font-size: 14;
$base-h6-line-height: 20;
$base-h6-font-weight: 400;
$base-h6-color: #c90;

// Blockquotes
$base-blockquote-font-family: "Yeseva One", cursive;
$base-blockquote-font-size: 18;
$base-blockquote-line-height: 36;
$base-blockquote-color: #fff;
$base-blockquote-cite-color: #fff;

// Colours
$gold: #c90;
$soft-gold: #cd9f0b;
$special-gold: #b6a46c;
$copy-grey: #494d4e;
$soft-grey: #f6f6f6;
$darkest-grey: #212121;
$black: #000;
$smoke: #f1f1f1;
$grey: #ccc;
$mid-grey: #999;
$white: #fff;